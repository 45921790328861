// App.js
import React from "react";
import "./App.css";

const App = () => {
  return (
    <div className="container">
      <header className="header">
        <h1>Kothapeta Brahmana Sangha Kalyana Mandapam</h1>
        <p className="tagline">Supporting the Brahmin Community of Kothapeta</p>
      </header>

      <section className="section">
        <h2>Genesis</h2>
        <p>
          It is generally seen that in many towns/cities various Kalyana
          Mandapams are available in the names of different communities for
          performing marriages or other functions. In our Kothapeta also there
          are a few Kalyana Mandapams in the name of different communities, but
          there is no such Mandapam in the name of Brahmin Community. To perform
          marriages/other functions, our members at Kothapeta have no
          alternative but to hire the function halls of other communities,
          thereby incurring heavy expenses for rent and for other facilities. In
          such scenario, the need for a separate Mandapam for our community also
          was given a thought seriously by veteran members of our Brahmana
          Sangham like (Late) Sri Evani Annapurna Sarama garu, (Late) Dr. Evani
          Subbayya Sastry garu, (Late) Sri Satyavolu Ramakrishna Rao garu, Sri
          Keerthi Venkateswarlu garu, Sri Musunuri Venkateswara Rao garu….only
          to name a few.
        </p>
      </section>

      <section className="section">
        <h2>Emergence</h2>
        <p>
          In the year 1990 Smt Mallampali Ratna Manikyamba garu (who attained
          Sadgati later) generously came forward to donate her site of nearly
          600 Sq.Yrds, worth nearly Rs 1.50 Crores, situated in a prime location
          in the heart of Kothapeta Main Road, for construction of Brahmin
          Kalyana Mandapam under the aegis of Kothapeta Brahmana Sangham.
          Fulfilling the noble wish of Manikyamba garu, the then committee
          constructed the present Brahmana Kalyana Mandapam, comprising only
          ground floor hall with basic related facilities, within the financial
          resources as available at that time.
        </p>
      </section>

      <section className="section">
        <h2>Renovation and Expansion</h2>
        <div className="expansion-details">
          <p>
            In the month of July 2024 new committee of Brahmana Sangham was
            formed under leadership of Sri Denduluri Prasad, (son of late Sri
            Denduluri Veerabhadra Rao garu). The committee deliberated on the
            need for renovation and expansion of the Kalyana Mandapam to suit
            requirements of users. With spontaneous financial contributions by
            local members the following works were taken up at a cost of around
            Rs 9.00 lakhs.
          </p>
          <ul>
            <li>
              Laying of Tiles in ground floor and back side of ground floor
            </li>
            <li>Renovation of Toilets</li>
            <li>Replacement of Doors/windows</li>
          </ul>
          <p>
            The committee embarked upon further expansion of kalyana mandapam
            and decided for construction of Air Conditioned first floor hall
            with a seating capacity of around 300 people, with following
            facilities:
          </p>
          <ul>
            <li>Appropriate stage for performing marriage / function</li>
            <li>One room each for Groom and Bride with attached bathrooms</li>
          </ul>
          <p>
            The estimated expenditure to undertake above expansion works would
            be around Rs 50.00 lacs as on date.Present available kitty of
            contributions by our local members,like-minded friends and outsiders
            who have sentimental attachment with Kothapeta stands around Rs
            25.00 lacs. Expecting more and more people would surely come forward
            to contribute for this noble cause,the committee decided to start
            the expansion works as listed above.
          </p>
          <p>
            The remodeled/expanded Kalyana Mandapam once ready, would surely be
            a boon to our community in Kothapeta. Further, It is our sincere
            intention to provide the Kalyana Mandapam for usage by our members
            with minimum rental charges, only to meet operating costs but not to
            derive any profit.
          </p>
        </div>
      </section>

      <section className="section">
        <h2>Appeal for Contributions</h2>
        <p>
          Kothapeta Brahmana Samgham appeals to one and all for their generous
          contributions for successful completion of the intended expansion
          works.
        </p>
        <div className="bank-details">
          <h3>Bank Account Details:</h3>
          <p>
            <strong>Account Name:</strong> SRI GAYATRI PARAMESWARA WELFARE
            ASSOCIATION
          </p>
          <p>
            <strong>Account Number:</strong> 62175865930
          </p>
          <p>
            <strong>Bank:</strong> State Bank Of India – Kothapeta Branch
          </p>
          <p>
            <strong>IFSC Code:</strong> SBIN0000982
          </p>
        </div>
      </section>

      <footer className="footer">
        <p>Thank you for your support and contributions!</p>
      </footer>
    </div>
  );
};

export default App;
